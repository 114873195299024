import React, {
  ChangeEvent,
  useCallback,
  useState,
} from 'react';

import { Alert } from '@mui/material';

import {
  decrypt,
  encrypt,
} from '../api/cipher';
import MainLayout from '../layouts/MainLayout';
import {
  ArrowDownIcon,
  ArrowRightIcon,
} from '../lib/icons';

function CipherPage() {
  const [textKey, setTextKey] = useState<string>('');
  const [originalText, setOriginalText] = useState<string>('');
  const [resultText, setResultText] = useState<string>('');
  const [hidden, setHidden] = useState<boolean>(true);

  const handleChangeTextKey = useCallback((e: ChangeEvent<HTMLInputElement>) => setTextKey(e.target.value), []);

  const handleChangeOriginalText = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => setOriginalText(e.target.value), []);

  const handleChangeResultText = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => setResultText(e.target.value), []);

  const handleEncrypt = useCallback(() => {
    setHidden(true);
    setResultText(encrypt(originalText, textKey));
  }, [originalText, textKey]);

  const handleDecrypt = useCallback(() => {
    setHidden(true);
    const result: string = decrypt(originalText, textKey);
    setResultText(result);
    if (result === '') {
      setHidden(false);
    }
  }, [originalText, textKey]);

  return (
    <MainLayout>
      <div>
        {/* <div className='text-3xl text-center font-semibold md:hidden'>Cipher</div> */}
        <div className={`mx-10 ${hidden ? 'hidden' : ''}`}>
          <Alert severity='warning'>Key and Text don't match</Alert>
        </div>
        <div className='flex flex-col space-y-2 md:flex-row md:space-x-5 justify-around items-end mx-10'>
          <div className='flex flex-col justify-center items-center md:items-end w-full space-y-2 md:space-y-5'>
            <input
              className='h-10 border-2 border-gray-300 rounded-lg w-1/2 p-2'
              type='text'
              value={textKey}
              onChange={handleChangeTextKey}
              placeholder='Enter key'
            />
            <textarea
              className='w-full h-48 md:h-96 p-5 border-2 border-gray-300 rounded-lg'
              value={originalText}
              onChange={handleChangeOriginalText}
              placeholder='Enter your text here'
            />
          </div>

          <div className='flex self-center'>
            <div className='flex md:flex-col items-center space-x-5 md:space-x-0 md:space-y-5'>
              <button
                className='md:h-10 pt-2 pb-2 md:pt-0 md:pb-0 bg-gray-300 rounded-lg px-2 md:pl-10 md:pr-5 flex items-center md:space-x-2 space-y-2 md:space-y-0 flex-col md:flex-row'
                onClick={handleEncrypt}>
                <p>Encrypt</p>
                <ArrowRightIcon className='h-6 w-6 hidden md:flex' />
                <ArrowDownIcon className='h-6 w-6 flex md:hidden' />
              </button>
              <button
                className='md:h-10 pt-2 pb-2 md:pt-0 md:pb-0 bg-gray-300 rounded-lg px-2 md:pl-10 md:pr-5 flex items-center md:space-x-2 space-y-2 md:space-y-0 flex-col md:flex-row'
                onClick={handleDecrypt}>
                <p>Decrypt</p>
                <ArrowRightIcon className='h-6 w-6 hidden md:flex' />
                <ArrowDownIcon className='h-6 w-6 flex md:hidden' />
              </button>
            </div>
          </div>

          <div className='flex flex-col items-start md:items-end w-full'>
            <textarea
              className='w-full h-48 md:h-96 p-5 border-2 border-gray-300 rounded-lg'
              value={resultText}
              onChange={handleChangeResultText}
              placeholder='Result'
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default CipherPage;
